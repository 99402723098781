export default [
  {
    title: 'Показатели',
    route: 'home',
    icon: 'PieChartIcon',
  },
  {
    title: 'Клиенты',
    route: { name: 'apps-todo-tag', params: { tag: 'low' } },
    // route: 'apps-todo-tag',
    icon: 'UsersIcon',
  },
  {
    title: 'Мероприятия',
    route: 'calendar',
    icon: 'KeyIcon',
  },
  /*{
    title: 'Чат',
    route: 'second-page',
    icon: 'MessageSquareIcon',
  },*/

]
